ion-img {
	width: 55px;
	height: 3.4375rem;
	padding: 0%;
	top: 0%;
	border-top: 0ch;
}

ion-title {
	font-family: 'Cinzel', serif;
}
